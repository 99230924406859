/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/emotion'
import { css } from '@emotion/core'
import track, { TrackingPropType } from 'react-tracking'
import { Helmet } from 'react-helmet'
import AdCard from 'components/AdCard'
import Avatar from 'components/Avatar'
import ReviewForm from 'components/ReviewForm'
import { NoDisplayMobile } from 'components/NoDisplay'
import ReviewsSummary from 'components/ReviewsSummary'
import CloseButton from 'components/CloseButton'
import Modal from 'components/Modal'
import { stampTime, eventDispatch } from 'utils/tracker'
import { authConditionReviewer } from 'utils/securityHelpers'
import { MQ, SPACERS } from 'utils/styleHelpers'
import { useCurrentBreakpointName } from 'react-socks'
import useAuthorization from 'hooks/useAuthorization'
import {
	AdColumnBox,
	AdColumnHeadline,
	AvatarWrapper,
	HeaderContainer,
	HeaderInfoBox,
	HeaderInfoBottomBoxLarge,
	HeaderInfoLeftBox,
	HeaderWrapper,
	NodeName,
	NodeLayoutWrapper,
	NodeLayoutWrapperBox,
	RightColumnBox,
} from './styles'

function NodeTemplateBase({ location, node, nodeType, pageTitle }) {
	const { isAuthorized } = useAuthorization(authConditionReviewer)
	const [isShowingReviewModal, setIsShowingReviewModal] = useState(false)
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const { Tabs } = node

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
			</Helmet>
			<NodeLayoutWrapper isMobile={isMobile} flexDirection="column" alignItems={[, 'center']}>
				<NodeLayoutWrapperBox>
					<Flex flexDirection="column">
						<Box>
							<HeaderContainer flexDirection="row" alignItems="center" justifyContent="space-evenly">
								<AvatarWrapper flexBasis="25%" alignSelf="center">
									<Avatar
										alt={pageTitle}
										size={[node.avatarWidth[0], node.avatarWidth[1]]}
										src={node.avatarImgUrl}
									/>
								</AvatarWrapper>
								<Box
									css={css`
										flex-basis: 75%;
										margin-left ${SPACERS.S};
										${MQ.M} {
											margin-left ${SPACERS.L};
										}
									`}
								>
									<Flex flexDirection="column" justifyContent="flex-start">
										<HeaderInfoBox>
											<Flex flexDirection="row" justifyContent={['space-between', 'flex-start']}>
												<Box
													css={css`
														flex-basis: 100%;
														${MQ.M} {
															flex-basis: 75%;
														}
														${MQ.L} {
															flex-basis: 50%;
														}
													`}
												>
													<HeaderWrapper>
														<NodeName>{node.name}</NodeName>
													</HeaderWrapper>
													<HeaderWrapper>
														<ReviewsSummary
															dispensaryId={node.id}
															averageRating={node.averageRating}
															totalReviews={node.totalReviews}
															justifyContent="flex-start"
														/>
													</HeaderWrapper>
													{node.HeaderInfoAboutLarge}
													<HeaderInfoLeftBox>{node.HeaderInfoLeft}</HeaderInfoLeftBox>
												</Box>
												{!isMobile && (
													<Box css={css`
														flex-basis: 50%;
													`}>
														<Flex
															flexDirection="column"
															css={css`
																height: 100%;
															`}
														>
															<Box
																css={css`
																	flex: 3;
																`}
															>
																{node.HeaderInfoRightTop}
															</Box>
															<Box
																css={css`
																	flex: 1;
																`}
															>
																{isAuthorized && (
																	<CloseButton
																		onClick={() => setIsShowingReviewModal(true)}
																	>
																		★ Leave a Review
																	</CloseButton>
																)}
															</Box>
														</Flex>
													</Box>
												)}
												{isShowingReviewModal && !isMobile && isAuthorized && (
													<Modal
														isDisplayed={isShowingReviewModal}
														onClose={() => setIsShowingReviewModal(false)}
													>
														<ReviewForm
															nodeID={node.id}
															nodeLabel={node.name}
															nodeState={node.state}
															nodeType={node.nodeType}
															nodeZip={node.zip}
															nodeCity={node.city}
															slug={node.slug}
															strainCategory={node.strainCategory}
														/>
													</Modal>
												)}
											</Flex>
										</HeaderInfoBox>
									</Flex>
								</Box>
							</HeaderContainer>
						</Box>
						<NoDisplayMobile>
							<HeaderInfoBottomBoxLarge dangerouslySetInnerHTML={{ __html: node.description }} />
						</NoDisplayMobile>
					</Flex>
				</NodeLayoutWrapperBox>
				<RightColumnBox>
					<Flex flexDirection="row">
						<Box flexBasis={['100%', '70%']}>{Tabs}</Box>
						{!isMobile && (
							<AdColumnBox flexBasis="30%" alignSelf="flex-start">
								<Flex flexDirection="column">
									<Box>
										<AdColumnHeadline>You may also be interested in...</AdColumnHeadline>
									</Box>
									<Box>
										<AdCard />
									</Box>
									<Box>
										<AdCard />
									</Box>
									<Box>
										<AdCard />
									</Box>
									<Box>
										<AdCard />
									</Box>
								</Flex>
							</AdColumnBox>
						)}
					</Flex>
				</RightColumnBox>
			</NodeLayoutWrapper>
		</>
	)
}
NodeTemplateBase.propTypes = {
	location: PropTypes.object,
	pageTitle: PropTypes.string,
	node: PropTypes.shape({
		HeaderInfoAboutLarge: PropTypes.node,
		HeaderInfoLeft: PropTypes.node,
		HeaderInfoRightTop: PropTypes.node,
		avatarWidth: PropTypes.array,
		avatarImgUrl: PropTypes.string,
		averageRating: PropTypes.number,
		description: PropTypes.string,
		id: PropTypes.string.isRequired,
		label: PropTypes.string,
		name: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		zip: PropTypes.string,
		slug: PropTypes.string,
		strainCategory: PropTypes.string,
		Tabs: PropTypes.node,
		totalReviews: PropTypes.number,
		nodeType: PropTypes.string.isRequired,
	}).isRequired,
	nodeType: PropTypes.string.isRequired,
}
export default track({ timestamp: stampTime() }, { dispatch: (data) => eventDispatch(data) })(NodeTemplateBase)
