import styled from '@emotion/styled'
import { FONT_SIZES, MQ, SPACERS } from 'utils/styleHelpers'
import { Box } from '@rebass/emotion'

export const InfoBox = styled(Box)`
	padding-top: ${SPACERS.M};
	${MQ.M} {
		padding-top: 0px;
	}
`
export const InfoValue = styled.span`
	font-size: ${FONT_SIZES.H6};
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
export const InfoLabel = styled.span`
	font-size: ${FONT_SIZES.H6};
	font-weight: 600;
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
