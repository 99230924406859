/* eslint-disable max-len */
import React, { useEffect, useState, useRef, useContext } from 'react'
import { object } from 'prop-types'
import { graphql } from 'gatsby'
import track, { TrackingPropType } from 'react-tracking'
import {
	EVENT_TYPES,
	TRACKING_ACTIONS,
	NODE_TYPES,
	DISPENSARY_PROFILE_IMAGE_SIZE,
	DISPENSARY_PROFILE_IMAGE_SIZE_MEDIUM,
	DISPENSARY_PROFILE_IMAGE_SIZE_LARGE,
	DISPENSARY_TABS,
} from 'utils/constants'
import GlobalLayout from 'components/GlobalLayout'
import DispensaryTabs from './components/DispensaryTabs'
import HeaderInfoAboutLarge from './components/HeaderInfoAboutLarge'
import HeaderInfoLeft from './components/HeaderInfoLeft'
import HeaderInfoRightTop from './components/HeaderInfoRightTop'
import NodeTemplateBase from 'templates/nodeTemplateBase'
import DispensarySchema from 'components/DispensarySchema'
import { stampTime, eventDispatch } from 'utils/tracker'
import { scrollTo } from 'utils/browserHelpers'
import { useCurrentBreakpointName } from 'react-socks'
import { SessionContext } from 'components/AuthContext'
function DispensaryTemplate({ data, location, tracking }) {
	const [productId, setProductId] = useState(null)
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const [activeTabIndex, setActiveTabIndex] = useState(0)
	const node = useRef(null)

	useEffect(() => {
		if (sessionID) {
			tracking.trackEvent({
				action: TRACKING_ACTIONS.VIEW_ACTION,
				event: EVENT_TYPES.WR_DISPENSARY_VIEW,
				nodeID: id,
				nodeType: NODE_TYPES.DISPENSARIES,
				nodeState: state || '',
				nodeCity: city || '',
				nodeZip: zip || '',
				nodeLabel: name,
				sessionID,
				uid,
				userIp,
				userLocation,
				version,
			})
		}
	}, [sessionID])

	useEffect(() => {
		if (location.state && location.state.productId) {
			setProductId(location.state.productId)
		}
		if (location.state && (location.state.productId || location.state.scrollTo) && isMobile) {
			setTimeout(() => scrollTo(170), 400)
		}
		return () => {
			setProductId(null)
			scrollTo(0)
		}
	}, [location])

	useEffect(() => {
		if (location.state && location.state.activeTabIndex) {
			setActiveTabIndex(location.state.activeTabIndex)
		}
	}, [location.state])

	const {
		allDispensariesJson: { edges: dispensaryData },
	} = data
	const {
		dispensary: {
			about,
			averageRating,
			address1,
			address2,
			city,
			geoLocation,
			phone,
			hours,
			id,
			imageUrl,
			name,
			reviews,
			state,
			slug,
			totalReviews,
			zip,
		},
	} = dispensaryData[0].node
	node.current = {
		HeaderInfoAboutLarge: (
			<HeaderInfoAboutLarge address1={address1} address2={address2} city={city} state={state} />
		),
		HeaderInfoLeft: (
			<HeaderInfoLeft
				address1={address1}
				address2={address2}
				city={city}
				id={id}
				location={location}
				name={name}
				phone={phone}
				sessionID={sessionID}
				state={state}
				zip={zip}
				uid={uid}
				userIp={userIp}
				version={version}
			/>
		),
		HeaderInfoRightTop: <HeaderInfoRightTop hours={hours} />,
		averageRating,
		description: about,
		id,
		name,
		Tabs: DispensaryTabs({
			activeTabIndex: activeTabIndex || (productId ? DISPENSARY_TABS.MENU : DISPENSARY_TABS.ABOUT),
			about,
			address1,
			address2,
			city,
			hours,
			isMobile,
			id,
			name,
			phone,
			productId,
			reviews,
			state,
			slug,
			tracking,
			zip,
		}),
		avatarWidth: [
			DISPENSARY_PROFILE_IMAGE_SIZE,
			DISPENSARY_PROFILE_IMAGE_SIZE_MEDIUM, 
			DISPENSARY_PROFILE_IMAGE_SIZE_LARGE,
		],
		avatarImgUrl:
			imageUrl || 'https://storage.googleapis.com/images.weedrater.com/strain-images/defaultStrainImage.png',
		nodeType: NODE_TYPES.DISPENSARIES,
		city,
		state,
		zip,
		totalReviews,
		slug,
	}
	return (
		<GlobalLayout location={location}>
			<DispensarySchema
				dispensary={{
					about,
					averageRating,
					address1,
					address2,
					geoLocation,
					city,
					phone,
					hours,
					id,
					imageUrl,
					name,
					reviews,
					state,
					slug,
					totalReviews,
					zip,
				}}
			/>
			<NodeTemplateBase
				node={node.current}
				nodeType={NODE_TYPES.DISPENSARIES}
				location={location}
				pageTitle={`${name} Marijuana Dispensary - ${city}, ${state} ${zip}`}
			/>
		</GlobalLayout>
	)
}
DispensaryTemplate.propTypes = {
	data: object,
	location: object,
	tracking: TrackingPropType,
}

export default track({ timestamp: stampTime() }, { dispatch: (data) => eventDispatch(data) })(DispensaryTemplate)

export const pageQuery = graphql`
	query dispensaries($id: String!) {
		allDispensariesJson(filter: { dispensary: { id: { eq: $id } } }) {
			edges {
				node {
					dispensary {
						about
						ADA
						address1
						address2
						admins {
							userId
							approved
						}
						atm
						averageRating
						AzLicensed
						city
						country
						creditCards
						delivery
						geoLocation {
							_latitude
							_longitude
						}
						hasEdibles
						hours
						id
						imageUrl
						labTested
						licensed
						medical
						name
						openVape
						paysLeafly
						paysPremiumFee
						phone
						preOrderEnabled
						retail
						reviews {
							id
							dateCreated
							defaultAvatarThemeIndex
							nodeName
							profileImageURL
							prefersUsername
							username
							firstName
							lastName
							rating
							reviewText
							reviewTitle
							reviewType
							reviewerName
							slug
							state
							userID
						}
						slug
						state
						storefront
						tagLine
						totalReviews
						UFCW
						veteranDiscount
						zip
					}
				}
			}
		}
	}
`
