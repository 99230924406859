import React from 'react'
import {
	AdCardWrapper,
	AdHeader,
	AdBody,
	AdIcon,
	AdIconBox,
	AdTitle,
	RatingDetails,
	StarRatingBox,
	InfoIconBox,
} from './styles'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import StarRating from 'components/StarRating'
import { Star } from 'components/Iconography'
import { COLORS, FONT_SIZES, SPACERS } from 'utils/styleHelpers'
function AdCard() {
	return (
		<AdCardWrapper flexDirection="column">
			<AdHeader>Featured Dispensary</AdHeader>
			<AdBody>
				<Flex flexDirection="column">
					<Box>
						<Flex flexDirection="row" justifyContent="space-between">
							<Box>
								<Flex flexDirection="row" alignItems="center">
									<AdIconBox>
										<AdIcon>Ad</AdIcon>
									</AdIconBox>

									<AdTitle> Alternative Therepies Group</AdTitle>
								</Flex>
							</Box>
							<InfoIconBox>
								<IoIosInformationCircleOutline />
							</InfoIconBox>
						</Flex>
					</Box>
					<Flex justifyContent="flex-start" flexDirection="row">
						<StarRatingBox alignSelf="center">
							<StarRating
								emptyStarColor={COLORS.MEDIUM_LIGHT_GRAY}
								name="reviewRating"
								renderStarIcon={Star({
									fontSize: FONT_SIZES.H6,
								})}
								starColor={COLORS.YELLOW}
								starCount={5}
								starRatingId="1032"
								isEditing={false}
								value={4}
							/>
						</StarRatingBox>
						<Box
							css={css`
								padding-bottom: ${SPACERS.S};
							`}
						>
							<RatingDetails>4 out of 5 stars</RatingDetails>
						</Box>
					</Flex>
				</Flex>
			</AdBody>
		</AdCardWrapper>
	)
}

export default AdCard
