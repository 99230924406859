import styled from '@emotion/styled'
import { COLORS, FONT_SIZES, SPACERS, MQ } from 'utils/styleHelpers'

export const SecondaryButton = styled.button`
	border: 1px solid ${({ isChecked, color }) => (isChecked ? color : COLORS.MEDIUM_LIGHT_GRAY)};
	border-radius: 4px;
	background: ${({ isChecked, color }) => (isChecked ? color : COLORS.LIGHT_GRAY)};
	color: ${({ isChecked }) => (isChecked ? COLORS.LIGHT_GRAY : COLORS.DARK_GRAY)};
	font-size: ${({ fontSize }) => fontSize || FONT_SIZES.H5};
	padding-right: ${SPACERS.M};
	padding-left: ${SPACERS.M};
	padding-top: ${SPACERS.M};
	padding-bottom: ${SPACERS.M};
	width: 100%;
	&:hover {
		${({ isChecked, color }) => (isChecked ? color : COLORS.LIGHT_GRAY)};
	}
	.dark & {
		color: ${({ isChecked }) => (isChecked ? COLORS.WHITE : COLORS.LIGHT_GRAY)};
		background: ${({ isChecked, color }) => (isChecked ? color : COLORS.BLACK)};
		border: 1px solid ${({ isChecked, color }) => (isChecked ? color : COLORS.MEDIUM_LIGHT_GRAY)};
	}
	${MQ.L} {
		font-size: ${FONT_SIZES.H7};
		padding-right: ${SPACERS.L};
		padding-left: ${SPACERS.L};
	}
`

export const SecondaryButtonLink = styled.a`
	border: 1px solid ${({ isChecked, color }) => (isChecked ? color : COLORS.MEDIUM_LIGHT_GRAY)};
	border-radius: 4px;
	background: ${({ isChecked, color }) => (isChecked ? color : COLORS.LIGHT_GRAY)};
	color: ${({ isChecked }) => (isChecked ? COLORS.LIGHT_GRAY : COLORS.DARK_GRAY)};
	font-size: ${FONT_SIZES.H5};
	padding-right: ${SPACERS.M};
	padding-left: ${SPACERS.M};
	padding-top: ${SPACERS.M};
	padding-bottom: ${SPACERS.M};
	width: 100%;
	text-decoration: none;
	transition: border 0.2s, background 0.2s, color 0.2s;
	&:hover {
		${({ isChecked, color }) => (isChecked ? color : COLORS.LIGHT_GRAY)};
		text-decoration: none;
	}
	.dark & {
		color: ${({ isChecked }) => (isChecked ? COLORS.WHITE : COLORS.LIGHT_GRAY)};
		background: ${({ isChecked, color }) => (isChecked ? color : COLORS.BLACK)};
		border: 1px solid ${({ isChecked, color }) => (isChecked ? color : COLORS.MEDIUM_LIGHT_GRAY)};
	}
	${MQ.L} {
		font-size: ${FONT_SIZES.H7};
		padding-right: ${SPACERS.L};
		padding-left: ${SPACERS.L};
	}
`
